



































import { Vue, Component } from 'vue-property-decorator';

@Component({ name: 'ContractSavingsProfilingPoliticallyExposedPersonError' })
export default class ContractSavingsProfilingPoliticallyExposedPersonError extends Vue {
  i18n_namespace = 'components.contract-savings.profiling.politically-exposed-person-error';

  endProcess() {
    this.$emit('endProcess');
  }
}

